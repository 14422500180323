.campoTextoAzul {
  width: 16px;
  border: 0.1px solid blue;
}

.campoFurca {
  padding: 1px;
}

.filaLarga {
  height: 108px;
  display: grid !important;
  align-items: center !important;
}

.fila {
  height: 19px;
}

.imagen {
  background-image: url("../assets/img/icons/dientes/11b.png");
  background-repeat: no-repeat;
  background-position: center;
}

.campoCheckbox {
  display: none;
}

.checkbox {
  border: 0.3px solid gainsboro;
  display: inline-block;
  padding: 3px;
}

input[type=checkbox]:checked+.checkAmarillo {
  background: yellowgreen;
  color: #fff;
}

input[type=checkbox]:checked+.checkRojo {
  background: #f00;
  color: #fff;
}

input[type=checkbox]:checked+.checkAzul {
  background: skyblue;
  color: #fff;
}

.campoTextoRojo {
  width: 16px;
  border: 0.1px solid red;
}

.campoTexto {
  font-size: small;
  height: 18px !important;
  width: 2rem;
  line-height: 1px !important;
  border: 0.1px solid gainsboro;
  text-align: center;
}

.celda {
  padding: 0rem !important;
  border: 0px !important;
  text-align: center !important;
  vertical-align: center !important;
  height: 20px;
}

.celdaTitulo {
  padding: 0rem;
  border-right: 0.1px solid gainsboro;
  text-align: center !important;
  vertical-align: center !important;
  background-color: skyblue;
}

.celdaPiezas {
  padding: 0rem;
  border-right: 0.1px solid gainsboro;
  text-align: center !important;
  vertical-align: center !important;
  background-color: #d6eef8;
}

/*Deshabilitar flechas en input de numeros*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}